import React from 'react'
import { MdArrowBackIos } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import CustomButton from '../../components/CustomButton'
import CustomInput from '../../components/CustomInput'
import { Colors } from '../../constants/colors'

const ForgotPassword = () => {
    const navigate=useNavigate()
    return (
        <div className='p-4'>
            <div className='py-4'>
            <a className="search-icons searchicons shadow" onClick={() => { navigate(-1) }} style={{ position: "absolute", top: 16, left: 16,display:"flex",justifyContent:'center',alignItems:'center'}}>
            <MdArrowBackIos color={Colors.black} />
            </a>
                {/* <button className=' btn rounded-circle shadow search-icons searchicons ' style={{height:'30',width:'30'}}><MdArrowBackIos /></button> */}
            </div>
            <div className='mt-4'>
                <h2 >
                    Enter the Email or WhatsApp
                </h2>

                <h2> number you used to sign up</h2>
                <CustomInput placeholder={"Email address*"} className={" mt-4 "}  />
                <CustomButton label={"Send email"} className={"btn-block mt-4"} color={Colors.orange500} />
            </div>
            <h6 className='mt-4 text-center text-secondary'> We’ll send you a password reset OTP</h6>

            <p className='text-center'  style={{ position: 'absolute', bottom: '10px', right: 0, left: 0,fontSize:12 }}>
                By using Fittestclub, You agree to our  <Link to="/t&c" style={{ color: Colors.primary }}>Terms & conditions</Link>,and <Link to="/privacy" style={{ color: Colors.primary }}>Privacy policy</Link>.
            </p>
        </div>
    )
}

export default ForgotPassword
