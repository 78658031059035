import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { customRequest } from "../../functions/request";
import { MdTranslate, MdChevronRight, MdLocationPin, MdChevronLeft } from "react-icons/md"
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentCity, setOtherCities, setPincode } from "../../redux/registrationSlice";


export default function LocationSelectionScreen() {
    let navigate = useNavigate();
    let currentCity = useSelector(state => state.registrationData.currentCity);
    let homePincode = useSelector(state => state.registrationData.pincode);
    let otherCities = useSelector(state => state.registrationData.otherCities);
    const dispatch = useDispatch();

    // let [currentCity, setCurrentCity] = React.useState('');
    // let [homePincode, setHomePincode] = React.useState('');
    // let [otherCities, setOtherCities] = React.useState('');

    const handleInput = (e) => {
        if (e.target.name == "current_city") {
            dispatch(setCurrentCity(e.target.value))
            // setCurrentCity(e.target.value);
        } else if (e.target.name == "home_pincode") {
            // setHomePincode(e.target.value);
            dispatch(setPincode(e.target.value));
        } else if (e.target.name === "other_cities") {
            // setOtherCities(e.target.value);
            dispatch(setOtherCities(e.target.value));
        }
    }

    const handleRegistration = () => {
        let userDetail = localStorage.getItem('register_user_data')
        userDetail = JSON.parse(userDetail);
        let registrationData = {
            'current_city': currentCity,
            'home_pincode': homePincode,
            'other_cities': otherCities,
            ...userDetail
        }


        customRequest("auth/register", registrationData).then((res) => {
            if (res.status && res.status == "success") {
                localStorage.setItem('@user', JSON.stringify(res.user));
                localStorage.setItem('@token', res.token)
                navigate("/home");
            } else {
                alert(res.message);
            }
        });

    };

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
            {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
            <img src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain"}} />
            <div style={{ height: "56%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2}}>
                <div style={{ padding: "24px 16px" }}>
                    <h4 style={{ fontSize: 20 }}>
                        <b style={{ fontFamily: "Open Sans" }}><span style={{ color: "#6945B4" }}>Add Cities</span> that matter to you..</b>
                    </h4>
                    <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "gray" }}>This helps us curate the most relatable & local content for you</span>

                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                            <label htmlFor="phone" style={{ fontSize: 10, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>Home Location</label>
                            <label htmlFor="phone" style={{ fontSize: 10, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }} onClick={() => { navigate("/locationSearchScreen", { state: { "type": "current_city" } }) }}>+ Add Current Location</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                            <MdLocationPin color="gray" />
                            <input type="text" onChange={handleInput} value={currentCity} name="current_city" placeholder="Add PIN Code to select locality" style={{ border: "none", backgroundColor: "transparent", fontSize: 12, marginLeft: 6, flex: 1 }} />
                            {/* <span style={{ fontSize: 12, marginLeft: 12 }}>Mumbai</span> */}
                            <MdChevronRight style={{ marginLeft: "auto", color: "gray" }} />
                        </div>
                    </div>

                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                            <label htmlFor="phone" style={{ fontSize: 10, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>Work or Business Location</label>
                            <label htmlFor="phone" style={{ fontSize: 10, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }} onClick={() => { navigate("/locationSearchScreen", { state: { "type": "pincode" } }) }}>+ Add Current Location</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                            <MdLocationPin color="gray" />
                            <input type="text" onChange={handleInput} value={homePincode} maxLength={6} name="home_pincode" placeholder="Add PIN Code to select locality" style={{ border: "none", backgroundColor: "transparent", fontSize: 12, marginLeft: 6, flex: 1 }} />
                            <MdChevronRight style={{ marginLeft: "auto", color: "gray" }} />
                        </div>
                    </div>

                    <div >
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                            <label htmlFor="phone" style={{ fontSize: 10, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }}>Other Location</label>
                            <label htmlFor="phone" style={{ fontSize: 10, color: "gray", marginTop: 24, fontFamily: "Open Sans", lineHeight: "1px" }} onClick={() => { navigate("/locationSearchScreen", { state: { "type": "other_cities" } }) }}>+ Add Current Location</label>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                            <MdLocationPin color="gray" />
                            <input type="text" onChange={handleInput} name="other_cities" value={otherCities} placeholder="Add PIN Code to select locality" style={{ border: "none", backgroundColor: "transparent", fontSize: 12, marginLeft: 6, flex: 1 }} />
                            <MdChevronRight style={{ marginLeft: "auto", color: "gray" }} />
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 28 }}>
                        <MdChevronLeft size={28} onClick={() => { navigate(-1) }} />
                        {/* <div style={{ fontSize: 16, fontFamily: "Open Sans", fontWeight: "600",cursor:"pointer" }} onClick={handleSkip}>Skip</div> */}
                        <button onClick={() => handleRegistration()} style={{ borderRadius: 60, backgroundColor: "#6945B4", border: "none", width: "80%", fontFamily: "Open Sans", fontSize: 14, color: "white", padding: "7px" }}>
                            Continue
                        </button>
                    </div>
                    {/* <Link to="/register" className="text-center mt-2">New User ? Register Here</Link> */}
                </div>
            </div>
        </div>
    );
}
