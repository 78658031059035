import React from 'react'
import MoodAvatar from '../moodAvatar'

const MoodBadge = ({ className, mood, onSelectMood, onRemove }) => {
    return (
        <button key={mood.id} type="button" className={className} >
            <MoodAvatar image={mood?.thumbnail_image} />
            <span onClick={onSelectMood}>{mood.mood_name}</span>
            <i style={{ marginLeft: '4px' }} className='fal fa-times ms-1 d-done' onClick={onRemove}></i>
        </button>

    )

}

export default MoodBadge