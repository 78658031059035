import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoggedUser } from '../../functions/helper';

const UserAvatar = () => {
    const user = getLoggedUser();
    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate=useNavigate();
    const userName = user?.full_name?.charAt(0).toUpperCase() || '';


    const handleNavigate=()=>{
        navigate('/myprofile')
    }


    return (
        <div onClick={handleNavigate}>
            {!imageLoaded ? 
            <div className='user-avatar' style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <span>{userName}</span>
            </div>
                : 
                <div className='user-avatar'>
                <img
                    src={user?.user_detail?.profile_pic}
                    onLoad={() => setImageLoaded(true)}
                    className="img-fluid"
                    style={{ display: imageLoaded ? 'block' : 'none' }}
                    alt="User Avatar"
                />
                </div>
            }
        </div>
    );
};

export default UserAvatar;
