import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { saveIdentity } from '../../functions/helper';
import { fetchIdentity } from '../../functions/services';
import useNavigateWithIdentification from '../../hooks/navigateWIthIdentification';
import NotFound from '../../screens/NotFound';
import OverlayLoader from '../OverlayLoader';

const RouteGuard = ({ children }) => {
    const params = useParams();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)


    useEffect(() => {
        setError(false)
        setLoading(true)
        fetchIdentity(params?.identificationToken)
            .then(res => {
                setLoading(false)
                if (res.status === 'failed') {
                    setError(true)
                }
                if (res?.status === 'success' && res?.token) {
                    saveIdentity(res.token)
                }
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
                setError(true)
            }
            )

    }, [])


    if (loading) {
        return <OverlayLoader />
    }

    if (error) {
        return <NotFound />
    }


    return children;
};

export default RouteGuard;
