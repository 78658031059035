import { useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import GoogleButton from "../../components/GoogleButton";
import axios from "axios";
import { setShowAlertPopup } from "../../redux/dataSlice";
import Footer from "../../components/Footer";
import useNavigateWithIdentification from "../../hooks/navigateWIthIdentification";

export default function RegisterPhoneScreen() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAgreeWithTerms, setIsAgreeWithTerms] = React.useState(false);
  const {navigateWithIdentification}=useNavigateWithIdentification()






  const handleGoogleSignUp = (user) => {
   
    if (!!user) {
      navigate('/register', { state: { email: user.email, google_id: user.sub, full_name: user.name, email_verified: user.email_verified, picture: user.picture } })
    } else {
      dispatch(
        setShowAlertPopup(
          {
            title: "Alert",
            message: "Something went wrong",
            show: true,
          }
        )
      )
    }
  }




  const handleGoogleAuth = useGoogleLogin({

    onSuccess: async tokenResponse => {
      console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => res.data).catch(e => {
          dispatch(
            setShowAlertPopup(
              {
                title: "Alert",
                message: "Something went wrong",
                show: true,
              }
            )
          )
        }
        )

      if (!!userInfo) {
        handleGoogleSignUp(userInfo)
      }
    },
  })


  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", zIndex: 1 }}>
      {/* <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} /> */}
      <img src={require("../../assets/main_logo.png")} style={{ height: "40vh", width: "100%", zIndex: 2, objectFit: "contain" }} />

      <span style={{ color: "white", position: "absolute", top: 20, right: 20, fontSize: 14, fontFamily: "Open Sans", zIndex: 2 }}>Sign up Later</span>
      <div style={{ height: "62%", backgroundColor: "white", boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 3 }}>
        <div style={{ padding: 16 }}>
          <h5 style={{ lineHeight: 1 }}>
            <b style={{ color: "#6945B4", lineHeight: 2 }}>Get Started</b><br />
            <b>Create an account now</b>
          </h5>
          <span style={{ fontSize: 14, color: "gray" }}>Create your account using your Google email account</span>

          <div style={{ marginTop: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <GoogleButton onClick={() => {
              if (!isAgreeWithTerms) {
                dispatch(
                  setShowAlertPopup(
                    {
                      show: true,
                      title: "Alert",
                      message: "Please accept terms & conditions.",
                    }
                  )
                )
                return;
              }
              handleGoogleAuth()
            }

            }  label={"Sign up with"} />



            {/* <GoogleLogin
              onSuccess={credentialResponse => { handleGoogleSignUp(credentialResponse.credential) }}
              onError={handleError}
            // useOneTap
              size="large"
              shape="circle"
              text="signup_with"
            /> */}
            {/* <div id="otpless"></div> */}
          </div>
          {/* <button onClick={() => navigate('/create-account')} className="py-2 btn-block  d-flex justify-content-center align-items-center " style={{ marginTop: 12, borderRadius: 60, display: 'flex', flex: 1, fontSize: 16, borderWidth: 1, borderColor: Colors.primary, color: Colors.primary, fontWeight: "500",backgroundColor:Colors.white }}>
            Sign up with Email or WhatsApp
          </button> */}
          {/* <div>
            <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 16 }}>Phone No.</label>
            <input
              type="text"
              name="phone"
              placeholder="Enter Phone"
              maxLength={10}
              onChange={handleInput}
              style={{ display: "block", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "7px 8px", fontFamily: "Open Sans", width: "100%" }}
            />
          </div>
          <button onClick={handleLogin} style={{ marginTop: 12, borderRadius: 60, backgroundColor: "#6945B4", border: "none", width: "100%", fontFamily: "Open Sans", color: "white", padding: "7px" }}>
            Send OTP
          </button> */}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 12 }}>
            <p style={{ textAlign: "center" }}>Already registered ? <p onClick={()=>navigateWithIdentification("")} style={{ color: "#6945B4" }}>Click here</p></p>
          </div>
          {/* Your terms and conditions checkbox */}
          <div className="mb-4" style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>

            <label>
              <input
                id="specifyColorRadioBtn"
                type="checkbox"
                checked={isAgreeWithTerms}
                onChange={(e) => setIsAgreeWithTerms(e.target.checked)}
                style={{ marginRight: "5px" }}
              />
              I agree with the T&C and Privacy Policy
            </label>
          </div>
          {/* <Link to="/registerphone" className="text-center mt-2">New User ? Register Here</Link> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
