export const Colors = {
    primary: '#6945B4',
    purple500:"#c5aef5",
    secondary: '#B0CE60',
    white: '#ffffff',
    lightGrey: '#f6f6f6',
    lightPurple:"#f1e9ff",
    lightGreen:"#f4fae5",
    green500:"#adbd84",
    black:"#000000",
    red:"#f54cec"
}