import React from 'react'
import { MdTranslate } from 'react-icons/md'
import { Link } from 'react-router-dom'
import CustomButton from '../../../components/CustomButton'
import CustomInput from '../../../components/CustomInput'
import GoogleButton from '../../../components/GoogleButton'
import { Colors } from '../../../constants/colors'
import useCreateAccount from './hook/useCreateAccount'

const CreateAccount = () => {
    const { handleCreateAccount, formData, handleSelectGender, handleInput, handleSelectLanguage, handleGoogleAuth, languages, loading, setIsAgreeWithTerms, isAgreeWithTerms, identity,navigateWithIdentification } = useCreateAccount()

    return (
        <div className="mb-4 pt-4" style={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            paddingTop: 10,
            height: '100vh',
            overflow: 'scroll',
        }} >
            <div className='p-4 m-4'>

                <img src={identity?.logo ? identity.logo : require("../../../assets/main_logo.png")} style={{ height: "25vh", width: "100%", zIndex: 2, objectFit: "contain" }} />
            </div>
            {/* <img alt="logo" src={require("../../assets/main_logo.png")} style={{ height: "35vh", width: "100%", zIndex: 2, objectFit: "contain", }} /> */}
            <div style={{ backgroundColor: Colors.white, boxShadow: "0 -4px 10px rgba(0,0,0,0.1)", paddingTop: "18px", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto", zIndex: 2 }}>
                <div className='p-4' style={{ display: 'flex', flexDirection: 'column', maxWidth: '600px', margin: '0 auto' }}>
                    <GoogleButton label={'Sign up with'} onClick={handleGoogleAuth} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <hr style={{ flex: 2 }} />
                        <p style={{ color: Colors.grey, flex: 0.5, textAlign: 'center', marginTop: 10 }}>OR</p>
                        <hr style={{ flex: 2 }} />
                    </div>
                    <h6 className='text-center' style={{ color: Colors.primary }} >
                        Create New Account
                    </h6>
                    <div className='d-flex mt-2 gap-1'>
                        {/* <CustomInput placeholder={"First Name*"} className={" mr-2"} /> */}
                        <CustomInput type={"text"} name="fullname" value={formData.fullname} onChange={handleInput} placeholder={"Full Name*"} />
                    </div>
                    <CustomInput type={"text"} name="email" value={formData.email} onChange={handleInput} placeholder={"Email*"} className={" mt-2 "} />
                    <CustomInput type={"text"} name="phone" value={formData.phone} onChange={handleInput} placeholder={"Mobile*"} maxLength={10} className={" mt-2 "} />
                    <CustomInput type={"text"} name="username" value={formData.username} onChange={handleInput} placeholder={"Username*"} className={" mt-2 "} />
                    <CustomInput type={"password"} name="password" value={formData.password} onChange={handleInput} placeholder={"Password*"} className={" mt-2 "} />

                    <h6 className='mt-4' >
                        Gender*
                    </h6>
                    <div className='d-flex ' >
                        <CustomButton onClick={() => handleSelectGender("male")} label={"Male"} className={`w-100 mr-2  ${formData.gender === 'male' ? "selected-gender" : ""}`} customStyle={{ color: formData.gender === 'male' ? Colors.primary : Colors.black }} color={Colors.lightGrey} />
                        <CustomButton onClick={() => handleSelectGender("female")} label={"Female"} className={`w-100 mr-2  ${formData.gender === 'female' ? "selected-gender" : ""}`} customStyle={{ color: formData.gender === 'female' ? Colors.primary : Colors.black }} color={Colors.lightGrey} />
                        <CustomButton onClick={() => handleSelectGender("other")} label={"Other"} className={`w-100  ${formData.gender === 'other' ? "selected-gender" : ""}`} customStyle={{ color: formData.gender === 'other' ? Colors.primary : Colors.black }} color={Colors.lightGrey} />
                    </div>
                    <div className='my-4' style={{ display: "flex", alignItems: "center", borderRadius: 8, backgroundColor: Colors.lightGrey, border: "none", padding: "10px 8px", fontFamily: "Open Sans", width: "100%" }}>
                        <MdTranslate />
                        {
                            languages === null || languages?.length == 0
                                ?
                                <span style={{ fontSize: 12, marginLeft: 12 }}>English (default)</span>
                                :
                                <select onChange={(e) => { handleSelectLanguage(e) }} style={{ flex: 1, paddingLeft: 20, backgroundColor: "transparent", border: "none", alignItems: 'center', color: Colors.black }}>
                                    {
                                        languages?.map((lang, index) => {
                                            return (
                                                <option key={index} value={lang.id}>{lang?.language_name}</option>
                                            )
                                        })
                                    }
                                </select>

                        }
                    </div>
                    {/* <Link className='text-center mt-3'>Forgot Password?</Link>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <hr style={{ flex: 2 }} />
                        <p style={{ color: Colors.grey, flex: 0.5, textAlign: 'center', marginTop: 10 }}>OR</p>
                        <hr style={{ flex: 2 }} />
                    </div> */}
                    <label style={{ whiteSpace: 'nowrap', fontSize: 14, marginTop: 10, display: 'flex' }}>
                        <input
                            id="specifyColorRadioBtn"
                            type="checkbox"
                            checked={isAgreeWithTerms}
                            onChange={(e) => setIsAgreeWithTerms(e.target.checked)}
                            style={{ marginRight: "5px" }}
                        />
                        I agree to receive communications via WhatsApp
                    </label>
                    <CustomButton onClick={handleCreateAccount} disabled={loading ? true : false} label={"Create New Account"} className={"btn-block"} color={Colors.secondary} />

                    <p className='text-center mt-3' style={{ right: 0, left: 0, fontSize: "0.8rem" }}>
                        By using Fittestclub, You agree to our  <Link to="/terms-and-condition" style={{ color: Colors.primary, fontWeight: "600" }}>Terms & conditions</Link>,and <Link to="/privacy-policy" style={{ color: Colors.primary, fontWeight: "600" }}>Privacy policy</Link>.
                    </p>
                    <p className='text-center mt-3'>
                        Already have an account? <span onClick={() => navigateWithIdentification("")} style={{ color: "#6945B4", fontWeight: "600",cursor:"pointer" }}>Log In</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount
