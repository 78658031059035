import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setShowAlertPopup } from '../redux/dataSlice'

function DayCard({ contestId, details,banner,hashTag,contestUnit}) {
    const navigate=useNavigate()
    const dispatch=useDispatch()

    const onClick=()=>{
       if(isFutureDate(details?.participate_date)) {
        dispatch(
            setShowAlertPopup(
              {
                show: true,
                title: "Alert",
                message: "Can't see the content of upcoming days.",
              }
            )
          )
          return;
       }
        navigate('/challenge-content',{state:{banner:banner,contestId:contestId,day_id:details.id,hashTag:hashTag,contestUnit:contestUnit}})
    }

    const isFutureDate = (dateString) => {
        const currentDate = moment();
        const inputDate = moment(dateString);
      
        return inputDate.isAfter(currentDate);
      };

      
    const cardBgColor={
        done: '#21B05C',
        pending:'#C4A489',
        toBeDone: '#cfcec2',
        new:'#ffffff'
    }


   // console.log(isFutureDate(details?.participate_date))
    

    const styles = {
        card: {
            backgroundColor: details?.contest_days_user?.status?.toLowerCase()==='done'?'#21B05C':isFutureDate(details?.participate_date)?'#D3D3D3':'#ffffff',
            border: 'none',
            borderRadius: '4px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            transition: 'box-shadow 0.3s ease-in-out',
            cursor: 'pointer',
            marginTop: '15px',
            margin:'10px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10vh',
            width: '10vh',
            display:'inline-flex',

        
        },

    }
   
    let cardClass = 'card'; // Default class

    if (window.innerWidth <= 768) {
      cardClass = 'card-small'; // Apply class for screens <= 768px
    } else if (window.innerWidth <= 480) {
      cardClass = 'card-tiny'; // Apply class for screens <= 480px
    }


    return (
        <div className='card'  onClick={onClick} style={styles.card} >
            <h4 >{`Day ${details?.name_of_day}`}</h4>
        </div>
    )
}

export default DayCard