import React from 'react'
import { useNavigate } from 'react-router-dom';
import useNavigateWithIdentification from '../hooks/navigateWIthIdentification';
import CustomButton from './CustomButton';

const CustomModal = ({ loading, message, hideModal, isCloseBtnVisible }) => {
    const { navigateWithIdentification } = useNavigateWithIdentification()
    let navigate = useNavigate();

    const gotoMyChallenge = () => {

        navigateWithIdentification('contestlist', { redirectToMyChallenge: true })
        hideModal()

    }
    const modalBackdropStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10001, // Adjust the z-index as needed
    };

    const modalStyle = {
        background: 'white',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        zIndex: 1000, // Adjust the z-index to ensure it's above the backdrop
        // maxWidth: '2',
        width: '300px',
        //  height:'180px'
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '5px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#333',
    };
    return (
        <div class="modal-fade" style={modalBackdropStyle}>
            <div className="modal-content" style={modalStyle}>
                {isCloseBtnVisible && <span style={closeButtonStyle} onClick={hideModal}>
                    &times;
                </span>}
                {loading ?
                    <div className='text-center'>
                        <div className="spinner-border text-danger  " role="status" style={{ width: 50, height: 50 }} />
                        <h5 className='mt-4'>Please wait...</h5>
                    </div>
                    : <>
                        <div class="modal-body text-center">
                            <img src={require('../assets/bestOfLuck.gif')} alt="loading..." />

                            <h5>{message}</h5>
                        </div>
                        <CustomButton onClick={gotoMyChallenge} label={" Go to My Challenge"} />
                        {/* <C className='btn btn-outline' onClick={gotoMyChallenge}  >
                   
                </C> */}
                    </>
                }

            </div>
        </div>
    )
}

export default CustomModal