import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
    name: "alertSlice",
    initialState: {
        alert: {
            show: false,
            message: "",
            button: false,
            color:'primary',
            buttonLabel:""
        }
    },
    reducers: {
      setAlert:(state,action)=>{
        const {message,show,color,button,buttonLabel}=action.payload
        state.alert.show=show
        state.alert.message=message
        state.alert.color=color
        state.alert.button=button
        state.alert.buttonLabel=buttonLabel

      }

    }
})

export const {
    setAlert
} = alertSlice.actions;

export default alertSlice.reducer;