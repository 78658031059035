import React from 'react'
import googleLogo from '../assets/google_logo.png'
import { Colors } from '../constants/colors'

const GoogleButton = ({ onClick, label }) => {
  

  const buttonStyle = {
   // borderRadius: '60px',
    display: "flex",
    flex: 1,
    //borderWidth: 1, borderColor: Colors.primary, color: Colors.primary,
    fontWeight:"500",
    backgroundColor:Colors.lightGrey,
    width:'100%'
  };

  const imgStyle = {
    height: '25px',
    width: '25px',
    marginRight: '10px',
  };
  return (
    <button onClick={onClick} className="py-2 btn  d-flex justify-content-center align-items-center " style={buttonStyle}>
      <img src={googleLogo} alt="Google Logo" style={imgStyle} />
      <p style={{ margin: '0px' }}>{label} Google</p>
    </button>
  )
}

export default GoogleButton
