import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customRequest } from '../functions/request';
import { setShowAlertPopup } from '../redux/dataSlice';
import ContestCard from './contestCard';
import Spinner from "react-spinkit";
import LanguageAndMoodModal from './moodsAndLanguageModal';
import { getLoggedUser, getUserToken } from '../functions/helper';
import { useParams } from 'react-router-dom';

export default function HomeContestSection({redirectToMyChallenge}) {
    const params=useParams()
    const user = getLoggedUser()
    let [isLoading, setIsLoading] = React.useState(true);
    let [selectedTab, setSelectedTab] = React.useState(redirectToMyChallenge?'mycontest':'foryou');
    let [contests, setContests] = React.useState(null);
    const [mycontest, setMyContest] = React.useState(null);
    const dispatch = useDispatch();
    const selectedLanaguageId = useSelector((state) => state.data.selectedLanguageId);
    const viewMoods = useSelector((state) => state.data.viewMoods);

    // console.log("inner")

    const getContests = () => {
        setIsLoading(true);
        let data = {
           // language_id: selectedLanaguageId,
            //  mood_id: viewMoods,
            // location: currentLocation,
            identification_token:params?.identificationToken
        }

        // if (!user) { 
        //     data = {}
        // }

        customRequest('contest/allcontests',data).then((res) => {
        //    console.log("all contest",res)
            setIsLoading(false);
            if (res?.contests?.length === 0) {
                setContests(
                    <div style={{ height: 300, width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <img src={require("../assets/empty_post.png")} style={{ height: 160, width: 240, objectFit: "contain" }} />
                        <span style={{ textAlign: "center" }}>No challenge found.</span>
                    </div>
                )
            } else {
                setContests(
                    res?.contests?.map((contest,index) => {
                        return (
                            <ContestCard key={index} id={contest.id} contest={contest} />
                        )
                    })
                )
            }
        }).catch(e=>{
            setIsLoading(false)
            console.log(e)
        })
    }

    const getMyContest = () => {
       
        customRequest('contest/my-contests',{user_id:user?.id}).then((res) => {
            if (res?.contests?.length === 0) {
                setMyContest(
                    <div style={{ height: 300, width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <img src={require("../assets/empty_post.png")} style={{ height: 160, width: 240, objectFit: "contain" }} />
                        <span style={{ textAlign: "center" }}>You haven't <br />participated in any contest yet.</span>
                    </div>
                )
            } else {
                setMyContest(
                    res?.contests?.map((contest) => {
                        return (
                            <ContestCard key={contest.id} id={contest.id} contest={contest} />
                        )
                    })
                )
            }
        }).catch((e)=>console.log('err',e))

    }

    const handleSelectMyContestTab = () => {
        if (getUserToken()) {
          //  console.log(getUserToken())
            setSelectedTab('mycontest');
        } else {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Login to see contest you participated in.",
                        showLoginBtn: true
                    }
                )
            );
        }
    }

    React.useEffect(() => {
        if (document.querySelector('.modal-backdrop')) {
            document.querySelector('.modal-backdrop').remove();
        }
        if (getUserToken()) {
            getMyContest();
        }
        getContests();
    }, [selectedLanaguageId, viewMoods])



    if (isLoading) {
        return (
            <div style={{ height: "100vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Spinner name="ball-scale-multiple" />
            </div>
        )
    }

    return (
        <div className="" id="contests" role="tabpanel" aria-labelledby="contests-tab">
            <div className="container px-1" style={{ marginBottom: 74 }}>
                <div className="d-flex flex-column bd-highlight my-2">

                    {
                        selectedTab === "foryou"
                            ?
                            contests
                            :
                            mycontest
                    }
                </div>
            </div>

            <LanguageAndMoodModal />


            <div className="mobilefooter">
                <ul>
                    <li onClick={() => { setSelectedTab('foryou') }} style={{ cursor: "pointer", width: "50%", textAlign: "center", backgroundColor: selectedTab === "foryou" ? "#6945B4" : "white", fontFamily: "Open Sans", fontSize: 12, color: selectedTab === "foryou" ? "white" : "black", padding: "6px 16px", borderRadius: 60 }}>
                        <span style={{}} >For you</span>
                    </li>
                    <li onClick={() => { handleSelectMyContestTab() }} style={{ cursor: "pointer", textAlign: "center", width: "50%", fontFamily: "Open Sans", fontSize: 12, borderRadius: 60, padding: "6px 16px", color: selectedTab === "mycontest" ? "white" : "black", backgroundColor: selectedTab === "mycontest" ? "#6945B4" : "white" }}>
                        <span >My Challenges</span>
                    </li>
                    {/* <li>
                        <a href="/#">Vote</a>
                    </li>
                    <li>
                        <a href="/#">Past Winners</a>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}