import React from 'react'
import { MdArrowBackIos } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import CustomButton from '../../components/CustomButton'
import CustomInput from '../../components/CustomInput'
import { Colors } from '../../constants/colors'

const ChangePassword = () => {
    const navigate=useNavigate()
    return (
        <div className='p-4'>
            <div className='py-4'>

            <a className="search-icons searchicons shadow" onClick={() => { navigate(-1) }} style={{ position: "absolute", top: 16, left: 16,display:"flex",justifyContent:'center',alignItems:'center'}}>
            <MdArrowBackIos color={Colors.black} />
            </a>            </div>
            <div className='mt-4'>
                <h2 >
                    Create a new password
                </h2>


                <CustomInput placeholder={"Create New Password"} className={" mt-4 "} />
                <CustomInput placeholder={"Re enter New Password"} className={" mt-3"} />
                <h6 className='mt-4 text-center'> Enter the OTP you have received via Email / WhatsApp</h6>

                <div className='d-flex justify-content-between mt-4'>
                    <CustomInput className={" pb-4"} />
                    <CustomInput className={" pb-4"} />
                    <CustomInput className={" pb-4 "} />
                    <CustomInput className={" pb-4 "} />

                </div>
                <h6 className='mt-2' style={{ float: 'right', color: Colors.primary }}>
                    Resend
                </h6>


                <CustomButton label={"Change password"} className={"btn-block mt-4"} color={Colors.orange500} />
            </div>

            <p className='text-center'  style={{ position: 'absolute', bottom: '10px', right: 0, left: 0,fontSize:12 }}>
                By using Fittestclub, You agree to our  <Link to="/t&c" style={{ color: Colors.primary }}>Terms & conditions</Link>,and <Link to="/privacy" style={{ color: Colors.primary }}>Privacy policy</Link>.
            </p>
        </div>
    )
}

export default ChangePassword
