import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import apiPath from '../../../../constants/apiPath'
import { getIdentity, getUserToken, phoneRegex, saveUser, saveUserToken } from '../../../../functions/helper';
import { customRequest } from '../../../../functions/request';
import useNavigateWithIdentification from '../../../../hooks/navigateWIthIdentification';
import { setShowAlertPopup } from '../../../../redux/dataSlice';


const useCreateAccount = () => {
    const [languages, setLanguages] = useState(null);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const identity = getIdentity();
    const { navigateWithIdentification } = useNavigateWithIdentification()
    const [isAgreeWithTerms, setIsAgreeWithTerms] = React.useState(true);
    const [formData, setFormData] = useState({
        fullname: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        gender: "",
        language_id: "",
        identification_token: identity?.identification_token
    });


    const handleInput = (e) => {

        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSelectLanguage = (param) => {
        setFormData((prev) => ({ ...prev, language_id: param }))
    }
    const handleSelectGender = (param) => {
        setFormData((prev) => ({ ...prev, gender: param }))
    }

    const getLanguages = () => {
        customRequest('auth/subdomain-languages').then((res) => {
            setFormData((prev) => ({ ...prev, language_id: res?.languages[0]?.id }))
            setLanguages(
                res.languages
            )
        }).catch(_ => { })
    }

    const handleCreateAccount = () => {

        if (!formData.fullname || !formData.email || !formData.phone || !formData.gender || !formData.password || !formData.username) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "All fields are required",

                    }
                ))
            return;
        }
        if (!phoneRegex.test(formData.phone)) {
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please enter valid phone number",

                    }
                ))
            return;
        }

        setLoading(true)
        customRequest(apiPath.SignUpWIthEmail, formData)
            .then(res => {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: res.message,
                           // showLoginBtn: true
                        }
                    ))
                navigateWithIdentification("login")

            })
            .catch(e => {
                dispatch(
                    setShowAlertPopup(
                        {
                            show: true,
                            title: "Alert",
                            message: e.message,
                        }
                    ))

            })
            .finally(() => setLoading(false))

    }

    const continueToGoogleSignup = (user) => {
        if (!!user) {
            navigateWithIdentification('register', { email: user.email, google_id: user.sub, full_name: user.name, email_verified: user.email_verified, picture: user.picture, identification_token: identity?.identification_token })
        } else {
            dispatch(
                setShowAlertPopup(
                    {
                        title: "Alert",
                        message: "Something went wrong",
                        show: true,
                    }
                )
            )
        }
    }




    const handleGoogleAuth = useGoogleLogin({
        onSuccess: async tokenResponse => {

            // fetching userinfo can be done on the client or the server
            const userInfo = await axios
                .get(apiPath.googleAuth, {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
                .then(res => res.data).catch(e => {
                    dispatch(
                        setShowAlertPopup(
                            {
                                title: "Alert",
                                message: "Something went wrong",
                                show: true,
                            }
                        )
                    )
                }
                )
            if (!!userInfo) {
                continueToGoogleSignup(userInfo)
            }
        },
    })

    React.useEffect(() => {
        getLanguages();
        const token = getUserToken()
        if (!!token) {
            navigateWithIdentification('', {}, { replace: true })
        }
    }, [])

    return { isAgreeWithTerms, setIsAgreeWithTerms, handleCreateAccount, getLanguages, formData, handleSelectGender, handleInput, handleSelectLanguage, handleGoogleAuth, languages, loading, identity, navigateWithIdentification };
}

export default useCreateAccount

