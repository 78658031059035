import React from 'react'
import { Colors } from '../constants/colors'

const CustomInput = ({ id, value, placeholder, onChange, customStyle, className, type, name ,icon,maxLength,...props}) => {
    const styles = {
        border: `1px solid ${Colors.primary}`,
        borderRadius: 5,
        backgroundColor: Colors.white,
        borderColor: Colors.white,
        width: '100%',
        alignItems:'center',
        backgroundColor:Colors.lightGrey

        // display:'flex'
    }
    return (icon ?
        <div className={`d-flex p-2 ${className}`} style={{ ...styles, ...customStyle }}>
            {icon}
            <input  {...props} maxLength={maxLength} id={id} name={name} type={type} placeholder={placeholder} onChange={onChange} value={value} style={{borderWidth:0,marginLeft:4,width:'100%', backgroundColor:Colors.lightGrey}} />

        </div>
        :
        <input {...props} maxLength={maxLength} className={`p-2 ${className}`} id={id} name={name} type={type} placeholder={placeholder} onChange={onChange} value={value} style={{ ...styles, ...customStyle }} />
    )
}

export default CustomInput