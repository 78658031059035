import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../components/CustomButton';
import useNavigateWithIdentification from '../hooks/navigateWIthIdentification';

const authArray = ["/", "/login", "/register"]

const NotFound = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { navigateWithIdentification } = useNavigateWithIdentification()
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };



  // useEffect(() => {
  //   if (authArray.includes(pathname)) {
  //   //  window.location.href = 'https://fittest.club';
  //   }
  // }, [pathname])


  return (
    <div className="container" style={containerStyle}>
      <h2 className="text-dark">404 - Not Found</h2>
      <p className="text-muted">Sorry, the page you are looking for does not exist.</p>
      {/* <CustomButton label={"Go Back to Home"} onClick={() => navigateWithIdentification("")} /> */}
    </div>
  );
};

export default NotFound;
