import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    user: null,
    identificationToken: "08515920240506"
}

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        resetAuth: (state) => initialState,
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setIdentificationToken: (state, action) => {
            state.identificationToken = action.payload;
        },


    }
})

export const {
    setUser,
    setIdentificationToken
} = authSlice.actions;

export default authSlice.reducer;