import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { customRequest } from "../../functions/request";

export default function OtpScreen() {
    let [OTP, setOTP] = React.useState("");
    let navigate = useNavigate();
    const location = useLocation();

    const handleInput = (e) => {
        if (e.target.name == "otp") {
            setOTP(e.target.value);
        }
    };

    const handleLogin = () => {
        customRequest("auth/verifyotp", { phone: location.state.phone, otp: OTP }).then((res) => {
            if (res.status && res.status == "success") {
                localStorage.setItem('@user', JSON.stringify(res.user));
                localStorage.setItem('@token', res.token);
                navigate('/home')
            } else {
                alert(res.message);
            }
        });
        // if (OTP != "") {
        // } else {
        //   customRequest("login", { phone: phoneNumber }).then((res) => {
        //     if (res.status && res.status == "success") {
        //       setShowOTP(true);
        //     } else {
        //       alert(res.message);
        //     }
        //   });
        // }
    };

    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", backgroundColor: "#6945B4" ,zIndex:1}}>
            <img src={require("../../assets/login-back.png")} style={{ position: "absolute", zIndex: 1, mixBlendMode: "soft-light" }} />
            <img src={require("../../assets/logo.png")} style={{ position: "absolute", top: "20%", left: "50%", transform: "translateX(-50%)", zIndex: 2, height: "36px" }} />
            <div style={{ height: "54%", backgroundColor: "white", borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: "auto" ,zIndex:2}}>
                <div style={{ padding: 16 }}>
                    <h4 style={{ fontSize: 19 }}>
                        <b style={{ color: "#6945B4", fontFamily: "Open Sans" }}>Verify it's you</b><br />
                        <b style={{ color: "black", fontFamily: "Open Sans" }}>Confirm the OTP</b>
                    </h4>
                    <span style={{ fontSize: 14, fontFamily: "Open Sans" }}>Kindly confirm the OTP sent your phone number ending with *****{location.state.phone.toString().substring(5)}</span>

                    <div >
                        <label htmlFor="phone" style={{ fontSize: 12, color: "gray", marginTop: 16, fontFamily: "Open Sans" }}>Enter OTP</label>
                        <div>

                        </div>
                        <input
                            type="text"
                            name="otp"
                            maxLength={6}
                            placeholder="Enter OTP"
                            style={{ display: "block", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)", border: "none", padding: "7px 8px", fontFamily: "Open Sans", width: "100%" }}
                            onChange={handleInput}
                        />
                    </div>
                    <button onClick={handleLogin} style={{ marginTop: 12, borderRadius: 60, backgroundColor: "#6945B4", border: "none", width: "100%", fontFamily: "Open Sans", color: "white", padding: "7px" }}>
                        Verify
                    </button>
                    {/* <Link to="/register" className="text-center mt-2">New User ? Register Here</Link> */}
                </div>
            </div>
        </div>
    );
}
