import React from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '../constants/colors';
import { getIdentity } from '../functions/helper';

const Footer = () => {
    const identity=getIdentity()
    return (
        <div  style={{padding: "20px", position: "fixed", bottom: 0, left: 0, width: "100%", textAlign: "center",zIndex:10,backgroundColor:Colors.lightGrey}}>
            <a href={identity?.link_one} target="_blank" style={{ color: Colors.primary, marginRight: "20px", }}>About</a>
            <a href={identity?.link_one} target="_blank" style={{ color: Colors.primary,marginRight: "20px" }}>T&C</a>
            <a href={identity?.link_two} target="_blank" style={{ color: Colors.primary }}>Privacy Policy</a>

        </div>
    );
};

export default Footer;