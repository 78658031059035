import React from 'react';
import { useNavigate } from 'react-router-dom';
import PinnedPostCard from '../components/pinnedPostCard';
import { PostCard } from '../components/postCard';
import RespectInfo from '../components/respectInfo';
import { SUBDOMAIN_NAME } from '../constants/subdomain';
import { getLoggedUser } from '../functions/helper';
import { customRequest } from '../functions/request';
import useNavigateWithIdentification from '../hooks/navigateWIthIdentification';

export default function MyProfileScreen() {
    const user= getLoggedUser()
    let [posts, setPosts] = React.useState(null);
    const [pinnedPosts, setPinnedPosts] = React.useState(null);
    const navigate = useNavigate();
    const [imageError, setImageError] = React.useState(false);
    const {navigateWithIdentification}=useNavigateWithIdentification()

    const getUserDetail = () => {
     
        customRequest('user/userdetail', { user_id: user?.id }).then((res) => {
            setPosts(
                res?.posts?.map((post) => {
                    return (
                        <PostCard key={post.id} post={post} />
                    )
                })
            )
        });
        getMyPinnedPosts();
    }


    const getMyPinnedPosts = () => {
        customRequest('post/mypinnedposts').then((res) => {
            if (res.pins.length > 1) {
                setPinnedPosts(
                    res.pins.map((pin) => {
                        if (pin.post !== null) {
                            return (
                                <PinnedPostCard key={pin.id} post={pin.post} />
                            )
                        }
                    })
                )
            }
        })
    }

    const handleLogout = () => {
        localStorage.removeItem("@user");
        localStorage.removeItem("@token");
        navigateWithIdentification('login',{}, { replace: true });
    }

    React.useEffect(() => {
        getUserDetail();
    }, [])

    if (user == null) {
        return <div></div>
    }

    const handleImageError = () => {
        setImageError(true)

    }

    return (
        <React.Fragment>
            <div className="main_warp">
                <div className="aboutheader">
                    {/* <div className="logohead">
                        <a href="/#"><img src="assets/images/logosm.png" alt="localbol" /></a>
                    </div> */}
                    <a className="search-icons searchicons" onClick={() => { navigate(-1) }} style={{ position: "absolute", top: 16, right: 16 }}>
                        <img src="assets/images/Arrow-Left.svg" />
                    </a>
                    <img alt='cover' src={require("../assets/cover_image.jpeg")} className="img-fluid"  />

                    <div className="user-profile">

                        {!user?.user_detail?.profile_pic ?
                            <img onError={handleImageError} src={`${process.env.PUBLIC_URL}/profile_image.png`} className="img-fluid" />
                            : <img onError={handleImageError} src={imageError ? `${process.env.PUBLIC_URL}/profile_image.png` : user?.user_detail?.profile_pic} className="img-fluid" />}
                    </div>



                    {/* <div data-bs-toggle="modal"
                        data-bs-target="#languageModal" className="right-fixed-icons">
                        <img height="25" width="25" src="assets/images/NavIcons.svg" alt="" />
                    </div> */}
                </div>
                <div className="aboutcontent">
                    <div className="aboutcontenttop">
                        <div className="container">
                            <div className="d-flex flex-column bd-highlight">
                                <div className="bd-highlight">
                                    <div className="d-flex flex-row bd-highlight">
                                        <div className="bd-highlight flex-grow-1">
                                            <h4>{user?.full_name}</h4>
                                        </div>
                                        <div className="bd-highlight">
                                            <button type="button" className="btn btn-orange mr-2" onClick={() => { navigate('/editprofile') }}>
                                                Edit Profile
                                            </button>
                                            <button type="button" className="btn btn-orange" onClick={() => { handleLogout() }}>
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        user?.user_detail?.bio
                                            ?
                                            <h5>{user.user_detail.bio}</h5>
                                            :
                                            <h5>I am a {SUBDOMAIN_NAME} User</h5>
                                    }
                                </div>
                            </div>
                        </div>
                        <RespectInfo />
                    </div>
                    <div className="accordion-section">
                        <div className="accordion" id="accordionExample">
                            {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="Social">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSocial" aria-expanded="true" aria-controls="collapseSocial">
                                        <span className="accordion-icons"><img src="assets/images/link.svg" /></span>Social Profiles <span className="accordion-text">Hide</span>
                                    </button>
                                </h2>
                                <div id="collapseSocial" className="accordion-collapse collapse show" aria-labelledby="headingSocial" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="socialmenu">
                                            <div className="autoplay">
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/Localbol.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/Facebook.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/Twitter.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/WhatsApp.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/Pinterest.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/Tinder.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/LinkedIn.svg" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="socialmenu-box">
                                                        <img src="assets/images/Instagram.svg" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="accordion-item">
                                <h2 className="accordion-header" id="headingInsights">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseInsights" aria-expanded="false" aria-controls="collapseInsights">
                                        <span className="accordion-icons"><img src="assets/images/Insightsicons.svg" /></span>Insights <span className="accordion-text">Hide</span>
                                    </button>
                                </h2>
                                <div id="collapseInsights" className="accordion-collapse collapse show" aria-labelledby="headingInsights" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="Insightslide">
                                            <div className="autoplaycard">
                                                <div className="Insightsbox">
                                                    <h3>1500+</h3>
                                                    <h6>Verified Posts</h6>
                                                </div>
                                                <div className="Insightsbox">
                                                    <h3>9600+</h3>
                                                    <h6>Reactions</h6>
                                                </div>
                                                <div className="Insightsbox">
                                                    <h3>160+</h3>
                                                    <h6>Comments</h6>
                                                </div>
                                                <div className="Insightsbox">
                                                    <h3>460+</h3>
                                                    <h6>Posts Shared</h6>
                                                </div>
                                                <div className="Insightsbox">
                                                    <h3>120+</h3>
                                                    <h6>Followers</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingAbout">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAbout" aria-expanded="false" aria-controls="collapseAbout">
                                        <span className="accordion-icons"><img src="assets/images/about.svg" /></span>About me <span className="accordion-text">Hide</span>
                                    </button>
                                </h2>
                                <div id="collapseAbout" className="accordion-collapse collapse show" aria-labelledby="headingAbout" data-bs-parent="#accordionExample">
                                    <div className="accordion-body pe-3">
                                        <p>
                                            {
                                                user?.user_detail?.about
                                                    ?
                                                    user.user_detail.about
                                                    :
                                                    null
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item mb-2 bgf3f3f3">
                                {
                                    pinnedPosts !== null
                                        ?
                                        <h2 className="accordion-header" id="headingPinned">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePinned" aria-expanded="false" aria-controls="collapsePinned">
                                                <span className="accordion-icons"><img src="assets/images/thumbtack.svg" /></span>Pinned Posts <span className="accordion-text">Hide</span>
                                            </button>
                                        </h2>
                                        :
                                        null
                                }
                                <div id="collapsePinned" className="accordion-collapse collapse show" aria-labelledby="headingPinned" data-bs-parent="#accordionExample">
                                    <div className="accordion-body p-0">
                                        <div className="variable-width" style={{ overflowX: "auto", display: "flex" }}>
                                            {
                                                pinnedPosts
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Postmobile">
                            <div className="Postmobile-top">
                                <div className="d-flex flex-row bd-highlight">
                                    <div className="p-2 bd-highlight align-self-center flex-grow-1">
                                        <h4>Posts</h4>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        <div className="btn-group">
                                            <button className="btn btn-link btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                All
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item active" href="#">Action</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                <li><hr className="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Postinnermobile">
                                {posts}
                            </div>
                        </div>
                        {/* <div className="showmore">
                            <button className="btn showmore-btn w-100" type="button">Show More..</button>
                        </div> */}
                    </div>
                </div>
                <div
                    className="modal windowmodal fade"
                    id="languageModal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header px-3 border-bottom">
                                <h5 className="modal-title">Personalize Yor Feed</h5>
                                <div
                                    className="modal-close ml-auto"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <img src="assets/images/Arrow-Left.svg" />
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex flex-column bd-highlight">
                                    <div className="py-1 bd-highlight">
                                        <h6>Show posts only in..</h6>
                                    </div>
                                    <div className="py-1 bd-highlight">
                                        <button type="button" className="btn btn-light-modal-active">English<i className="fal fa-times ms-1"></i></button>
                                        <button type="button" className="btn btn-light-modal">हिंदी<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">मराठी<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">ગુજરાતી<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">কাজ<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">മലയാളം<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">Arabic<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">தமிழ்நாடு<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">Mandarin<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">French<i className="fal fa-times ms-1 d-none"></i></button>
                                        <button type="button" className="btn btn-light-modal">Bengali<i className="fal fa-times ms-1 d-none"></i></button>
                                    </div>
                                    <div className="py-1 bd-highlight border-bottom mb-2">
                                        <h6 className="mb-2"><small>More languages..</small></h6>
                                    </div>
                                    <div className="py-1 bd-highlight">
                                        <h6>What moods would like to see ?</h6>
                                    </div>
                                    <div className="py-1 bd-highlight">
                                        <button type="button" className="btn btn-light-modal-active">All</button>
                                        <button type="button" className="btn btn-light-modal">mumbaivibes</button>
                                        <button type="button" className="btn btn-light-modal">nightlife</button>
                                        <button type="button" className="btn btn-light-modal">couplespoint</button>
                                        <button type="button" className="btn btn-light-modal">bikerace</button>
                                        <button type="button" className="btn btn-light-modal">politics</button>
                                        <button type="button" className="btn btn-light-modal">kuchbhibol</button>
                                        <button type="button" className="btn btn-light-modal">localupdates</button>
                                        <button type="button" className="btn btn-light-modal">marinedrive</button>
                                        <button type="button" className="btn btn-light-modal">politics</button>
                                    </div>
                                    <div className="py-1 bd-highlight">
                                        <h6><small>More Moods..</small></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal windowmodal fade"
                id="Participate"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header px-3">
                            <div
                                className="modal-close ml-auto"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src="assets/images/Arrow-Left.svg" />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex flex-column bd-highlight text-center">
                                <div className="p-2 bd-highlight">
                                    <div className="modallogoimg">
                                        <img src="assets/images/Appicon.png" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="p-2 bd-highlight text-center">
                                    <h2>Install the app..</h2>
                                    <p className="text-center">Install the app today &amp; get access to Amet minim mollit non<br />deserunt ullamco est sit aliqua dolor do amet sint. </p>
                                </div>
                                <div className="pb-2 bd-highlight">
                                    <div className="playstore"><a href="#"><img src="assets/images/Playstore.png" className="img-fluid" /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sharepost">
                <div className="modal fade" id="shareModal" tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen-sm-down">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className="d-flex flex-column bd-highlight">
                                    <div className="px-4 bd-highlight">
                                        <div className="topline"></div>
                                    </div>
                                    <div className="px-4 bd-highlight">
                                        <h6>Share</h6>
                                    </div>
                                    <div className="px-3 bd-highlight">
                                        <div className="input-group mb-3">
                                            <input className="form-control" type="text" value="https://localbol.com/beautifuldoor" aria-label="readonly input example" readonly />
                                            <span id="liveToastBtn" className="input-group-text">
                                                <i className="far fa-copy fa-lg"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="px-3 pb-3 bd-highlight">
                                        <div className="mailbox">
                                            <div className="d-flex flex-row bd-highlight align-items-center">
                                                <div className="p-2 bd-highlight">
                                                    <div className="mailboxicons">
                                                        <img src="assets/images/Localbol.svg" width="30" height="30" />
                                                    </div>
                                                </div>
                                                <div className="p-2 bd-highlight">
                                                    <h5>Check out this beautiful door on Localbol</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 py-2 bd-highlight border-top">
                                        <div className="mailboxsocialmenu">
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Profileimages2.jpg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemIcons">
                                                    <img src="assets/images/Hangouts.svg" className="img-fluid" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Name</div>
                                            </div>
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Profileimages2.jpg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemIcons">
                                                    <img src="assets/images/Hangouts.svg" className="img-fluid" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Done</div>
                                            </div>
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Profileimages2.jpg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemIcons">
                                                    <img src="assets/images/Messages_logo.svg" className="img-fluid" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Massial</div>
                                            </div>
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Profileimages2.jpg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemIcons">
                                                    <img src="assets/images/WhatsApp2.svg" className="img-fluid" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Massial</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 py-2 bd-highlight border-top">
                                        <div className="mailboxsocialmenu">
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Gmail_Icon.svg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Gmail</div>
                                            </div>
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Messages_logo.svg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Messages</div>
                                            </div>
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/Google_Drive.svg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">Drive</div>
                                            </div>
                                            <div className="mailboxsocialmenu-item">
                                                <div className="mailboxsocialmenu-itemimg">
                                                    <img src="assets/images/WhatsApp2.svg" />
                                                </div>
                                                <div className="mailboxsocialmenu-itemName">WhatsApp</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bd-highlight border-top">
                                        <div className="d-flex flex-row bd-highlight">
                                            <div className="px-4 py-2 bd-highlight text-center">
                                                <i data-bs-dismiss="modal" className="far fa-chevron-left"></i>
                                            </div>
                                            <div className="p-2 bd-highlight w-75">
                                                <div className="topline"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}