import { useDispatch } from 'react-redux';
import { setShowAlertPopup } from '../redux/dataSlice';

const useAlert = () => {
    const dispatch = useDispatch()

    function showAlertPopup(message) {
        dispatch(
            setShowAlertPopup({
                show: true,
                title: 'Alert',
                message,
            })
        );
    }

    return { showAlertPopup }
}

export default useAlert