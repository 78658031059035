import { getIdentificationToken } from "./helper";
import { customRequest, getApiService, postApiService } from "./request";

export const getUserDetails = (user_id) => {
  return customRequest("user/userdetail", { user_id: user_id });
};


export const fetchPost = (body) => {
  return postApiService("post/allposts", body);
};


export const fetchAllContests = (body) => {
  return postApiService("allcontests", body);
};


export const fetchIdentity = (identification_token) => {
 // const identification_token = getIdentificationToken()
  return postApiService('auth/identification-token', { identification_token })
}