import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContestScreen from "../screens/challenges";
import CreatorProfileScreen from "../screens/CreatorProfile";
import LoginScreen from "../screens/auth/Login";
import LoginWithEmail from "../screens/auth/LoginWithEmail";
import FaqScreen from "../screens/Faq";
import InfoScreen from "../screens/Info";
import RegisterScreen from "../screens/auth/Register";
import OtpScreen from "../screens/auth/otp";
import MyProfileScreen from "../screens/myProfile";
import RegisterPhoneScreen from "../screens/auth/registerPhone";
import RegisterVerifyOtpScreen from "../screens/auth/registerVerifyOtp";
import SelectLocationScreen from "../screens/selectLocation";
import LanguageSelectionScreen from "../screens/auth/languageSelection";
import LocationSelectionScreen from "../screens/auth/locationSelection";
import LocationSearchScreen from "../screens/locationSearch";
import AlertPopup from "../components/alertPopup";
import ContestListScreen from "../screens/contestList";
import ChallengeContent from "../screens/ChallengeContent";
import DynamicAlert from "../components/DynamicAlert";
import { useSelector } from "react-redux";
import CreateAccount from "../screens/auth/CreateAccount/CreateAccount";
import ForgotPassword from "../screens/auth/ForgotPassword";
import ChangePassword from "../screens/auth/ChangePassword";
import TermsAndCondition from "../screens/TermsAndCondition";
import Privacy from "../screens/Privacy";
import ShareContest from "../screens/ShareContest";
import NotFound from "../screens/NotFound";
import EditPostScreen from "../screens/editPost";
import NewPostScreen from "../screens/newPost";
import ProtectedRoute from "../components/protectedRoute";
import RouteGuard from "../components/routeGuard";
import ComingSoon from "../screens/comingSoon";

export default function NavigationManager() {
  const { alert } = useSelector(state => state.alert)

  return (
    <BrowserRouter>
      <AlertPopup />
      {alert?.show && <DynamicAlert />}
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/:identificationToken" element={<RouteGuard><ContestListScreen/></RouteGuard>} />
        <Route path="/:identificationToken/login" element={<RouteGuard><LoginScreen /></RouteGuard>} />
        <Route path="/:identificationToken/login-with-email" element={<RouteGuard><LoginWithEmail /></RouteGuard>} />
        <Route path="/:identificationToken/register" element={<RouteGuard><RegisterScreen /></RouteGuard>} />
        <Route path="/:identificationToken/signup-with-google" element={<RouteGuard><RegisterPhoneScreen /></RouteGuard>} />
        <Route path="/:identificationToken/registerverifyotp" element={<RegisterVerifyOtpScreen />} />
        <Route path="/selectlocation" element={<ProtectedRoute Component={SelectLocationScreen} />} />
        <Route path="/languageSelectionScreen" element={<LanguageSelectionScreen />} />
        <Route path="/locationSelectionScreen" element={<LocationSelectionScreen />} />
        <Route path="/locationSearchScreen" element={<LocationSearchScreen />} />
        <Route path="/:identificationToken/create-account" element={<RouteGuard><CreateAccount /></RouteGuard>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/verifyotp" element={<OtpScreen />} />
        <Route path="/:identificationToken/home" element={<ContestListScreen />} />
        <Route path="/:identificationToken/contest" element={<ContestScreen />} />
        <Route path="/:identificationToken/contestlist" element={<RouteGuard><ContestListScreen /></RouteGuard>} />
        <Route path="/contest/:contestId" element={<ShareContest />} />
        <Route path="/myprofile" element={<ProtectedRoute Component={MyProfileScreen} />} />
        <Route path="/userprofile" element={<CreatorProfileScreen />} />
        <Route path="/faq" element={<FaqScreen />} />
        <Route path="/info" element={<InfoScreen />} />
        <Route path="/challenge-content" element={<ChallengeContent />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
        <Route path="/editpost/:postid" element={<ProtectedRoute Component={EditPostScreen} />} />
        <Route path="/newpost" element={<ProtectedRoute Component={NewPostScreen} />} />
        <Route path="/comingSoon" element={<ComingSoon />} />
        <Route path="/not-found" element={<NotFound />} />
        {/* The catch-all route for 404 errors */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
