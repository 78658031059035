import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Spinner from "react-spinkit";
import CustomModal from '../components/CustomModal'
import VideoCard from '../components/VideoCard'
import { customRequest } from '../functions/request'
import { setShowAlertPopup } from '../redux/dataSlice'
import { MdArrowBack, MdChevronLeft } from 'react-icons/md';
import { Colors } from '../constants/colors'
import Running from '../assets/Running.svg'
import Coin from '../assets/Coin.svg'
import Ads from '../assets/Ads.svg'
import Calender from '../assets/Calender.svg'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from '../components/CustomInput';
import moment from 'moment'
import OverlayLoader from '../components/OverlayLoader'
import useNavigateWithIdentification from '../hooks/navigateWIthIdentification'

const ChallengeContent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [contestAlert, setContestAlert] = useState({ visible: false, message: '' });
  const [fetching, setFetching] = useState(false)
  const [showActivity, setShowActivity] = useState(true)
  const [showShareExperience, setShowShareExperience] = useState(true)
  const [activityDate, setActivityDate] = useState(new Date())
  const [activityData, setActivityData] = useState("")
  const [reload, setReload] = useState(false)
  const { navigateWithIdentification } = useNavigateWithIdentification()

  const action = Object.freeze({
    TASK: 'Task',
    ADS: 'Ads',
    POST: 'Post'
  })

  const dispatch = useDispatch()


  useEffect(() => {
    setFetching(true)
    // console.log(location.state)
    const path = 'contest/my-contest-day'
    const body = {
      "id": location.state.day_id,
      "contest_id": location.state.contestId
    }
    customRequest(path, body).then((resp) => {
     // console.log('days', resp)
      if (!!resp.contestDays) {
        setData(resp.contestDays)
      }

    }
    ).catch(
      // (error) => console.log(error)
    ).finally(() => setFetching(false))

  }, [reload])


  const backgroundImageUrl = 'https://marketplace.canva.com/EAE5pB1Y1-M/1/0/1600w/canva-beige-minimalist-aesthetic-daily-vlog-youtube-thumbnail-kuQZ23Gp9lM.jpg' // Replace with your image file path


  const contentType = {
    audio: <div
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        width: '100%', // Adjust the width and height as needed
        height: '30vh',
        overflow: 'hidden',
      }}
    >
      <audio src={data?.link} className="container" controls style={{ width: '100%', height: '95%', opacity: 0.4 }}></audio>
    </div>,
    video: <VideoCard videoSource={data?.link} />,
    text: null,
    // <div className='btn btn-light  my-2' style={{ borderRadius: '12px', height: '25vh', width: '90vw', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }} >
    //         <h6> {data?.detail ? `${data?.detail} (10 points)` : 'No content found'}</h6>
    //       </div>,
    image: <img alt='challenge'
      // onError={(e) => { e.target.src = 'https://img.freepik.com/free-vector/image-upload-concept-landing-page_23-2148309693.jpg' }} 
      src={data?.link} className="img-fluid post-media-content-img" style={{ maxHeight: "400px", objectFit: "cover", width: "100vw" }} />

  }

  const handleCompleteTask = () => {
    setLoading(true)
    customRequest('contest/contest-day-task', {
      contest_id: location.state.contestId, contest_day_id: location.state.day_id, "status": "Done",
      "action": "Task"
    }).then(res => {
      setReload(!reload)
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: res.message,
          }
        )
      )

    }).catch(e =>
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Something went wrong",
          }
        )
      )
    ).finally(() => setLoading(false))

  }



  const handleContestDayActivity = async () => {
    if (!activityData) {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: "Please fill activity data",
          }
        )
      )
      return
    }
    setLoading(true)
    customRequest('contest/contest-day-activity', {
      contest_id: location.state.contestId,
      contest_day_crm_id: moment(activityDate).format("D-MMM-YYYY"),
      activity_data: activityData,
      activity_date: moment(activityDate).format("D-MMM-YYYY")
    }).then(res => {
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: res.message,
          }
        )
      )
      //  console.log('activity', res)
      // setContestAlert({ visible: true, message: res.message })


    }).catch(e => {
      console.log(e)
      dispatch(
        setShowAlertPopup(
          {
            show: true,
            title: "Alert",
            message: e.message,
          }
        )
      )
    }
    ).finally(() => setLoading(false))

  }

  const completeTaskWithPost = () => {
    navigate('/newpost', { state: { source: "contest", contest_id: location.state.contestId, day_id: location.state.day_id, action: action.POST, status: 'Done', hashTag: `#${location?.state?.hashTag}` } })
  }

  if (fetching) {
    return (
      <div style={{ height: "100vh", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Spinner name="ball-scale-multiple" />
      </div>
    )
  }
  return (
    <>
      {loading && <OverlayLoader />}

      <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
        <MdArrowBack size={24} onClick={() => { navigateWithIdentification('') }} cursor="pointer" />
        <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}>Challenges</h4>

        <a className="search-icons searchicons" style={{ marginLeft: 'auto' }} onClick={() => { navigate(-1) }}>
          <img src="assets/images/Arrow-Left.svg" />
        </a>
      </div>

      <div className="main_warp">
        <div className="pb-1 bd-highlight">
          <div className="text-center">
            {contentType[data?.content_type]}
          </div>
          {
            data?.detail ?
              <p className='text-justify container  mt-4' dangerouslySetInnerHTML={{ __html: data?.detail }}></p>
              :
              <h4 className='text-center mt-4'>{`Day #${data?.name_of_day}`}</h4>
          }
        </div>
        <div className='container py-2 ' >

          <p className='text-center ' >Please spare a moment to complete today's challenge by filling out the form below.</p>
          <div style={{ backgroundColor: Colors.lightGrey }}>
            <div onClick={() => setShowActivity(!showActivity)} className="p-2" style={{ backgroundColor: Colors.primary }}>
              <div className="p-2 text-white d-flex justify-content-between align-items-center fs-5 font-weight-bold">
                <p className="m-0 ">Share Activity Data</p>
                <MdChevronLeft size={25} style={{ transform: 'rotate(-90deg)' }} />
              </div>
            </div>
            {showActivity && <div >
              <p className='mt-2  text-center '>Please provide your activity details</p>
              <div className='p-2 d-flex justify-content-between align-items-center'>
                <div>
                  <p>Activity Date</p>
                  <div className=' d-flex justify-content-between align-items-center' style={{ backgroundColor: Colors.white, borderRadius: 5, }} >
                    <DatePicker className='form-control custom-datepicker' minDate={moment().subtract(7, 'days')?._d} maxDate={new Date()} selected={activityDate} onChange={(date) => setActivityDate(date)} style={{ border: 'none' }} />
                    <img src={Calender} alt="SVG Image" style={{ marginRight: '10px' }} />
                  </div>
                </div>
                <div className='mx-1' />
                <div  >
                  <p>Activity Data</p>
                  <div className=' d-flex justify-content-between align-items-center' style={{ backgroundColor: Colors.white, borderRadius: 5, }} >
                    <CustomInput value={activityData} onChange={(e) => setActivityData(e.target.value)} placeholder={location?.state.contestUnit ? location?.state.contestUnit : ""} className={"bg-white"} type={"text"} customStyle={{ bordeWidth: 0, backgroundColor: Colors.white, borderColor: Colors.white }} />
                    <img style={{ marginRight: "6px" }} src={Running} alt="SVG Image" />
                  </div>

                </div>
              </div>
              <div className="p-2 text-center">
                <button onClick={handleContestDayActivity} className='shadow-sm btn btn-rounded text-center text-white' style={{ backgroundColor: Colors.primary }}>
                  Submit
                </button>
              </div>
            </div>
            }

          </div>

          <div className='mt-4' style={{ backgroundColor: Colors.lightGrey }}>
            <div onClick={() => setShowShareExperience(!showShareExperience)} className="p-2" style={{ backgroundColor: Colors.secondary }}>
              <div className="p-2 text-white d-flex justify-content-between align-items-center fs-5 font-weight-bold">
                <p className="m-0">Share Experience</p>
                <MdChevronLeft size={25} style={{ transform: 'rotate(-90deg)' }} />
              </div>
            </div>
            {showShareExperience &&
              <div className='d-flex justify-content-center'   >
                {data?.contest_days_user?.status?.toLowerCase() === 'done' ?
                  <div className='text-center'>
                    <h5 className='text-success mt-4'>Task completed</h5>
                    <img src={require('../assets/done.gif')} style={{mixBlendMode:"multiply" }} />
                  </div> :
                  <div className='p-2  mt-2' style={{ width: '80%' }} >
                    <div
                      onClick={completeTaskWithPost}
                      className="shadow-sm p-2 mt-4 btn btn-block text-center"
                      style={{ backgroundColor: Colors.secondary, color: Colors.white }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={Coin} alt="SVG Image" style={{ marginRight: '10px' }} />
                        <p className="m-0">Add a post & complete</p>
                      </div>
                    </div>

                    <div
                      onClick={handleCompleteTask}
                      className="shadow-sm p-2 my-4 btn btn-block text-center"
                      style={{ backgroundColor: Colors.secondary, color: Colors.white }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={Ads} alt="SVG Image" style={{ marginRight: '10px' }} />

                        <p className="m-0">Challenge completed</p>
                      </div>
                    </div>


                  </div>
                }

              </div>
            }

          </div>
        </div>
        {contestAlert?.visible && <CustomModal message={contestAlert?.message} hideModal={() => setContestAlert({ visible: false, message: '' })} />}


      </div>
    </>
  )
}

export default ChallengeContent