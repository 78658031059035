import React from 'react'
import { Colors } from '../constants/colors'

const CustomButton = ({onClick=()=>{}, color,label,className,customStyle,...props}) => {
  return (
    <button onClick={onClick} className={`btn rounded  mt-2 p-2 ${className} ${customStyle?.color?null:'text-white'}`} style={{backgroundColor:color||Colors.primary,fontWeight:"500",...customStyle}} {...props} >
      {label}</button>
  )
}

export default CustomButton