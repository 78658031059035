import React, { useState } from 'react';



const HorizontalContestTab = ({selectedTab,handleSelectTab,tabsData,hideScheduleTab}) => {
  const [scrollX, setScrollX] = useState(0);
  //const [selectedTab, setSelectedTab] = useState( 'Challenge Details',)

  const handleTabClick = (tab,index) => {
    handleSelectTab(tab)
    // Calculate the scroll position based on the tab index
    // const tabWidth = 120; // Adjust this based on your tab size
    // setScrollX(index * tabWidth);
  };

  return (
    <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap',backgroundColor:'#ffffff',}}>
      <div
        style={{
          display: 'flex',
          padding: '10px',
        //   width: `${tabsData.length * 120}px`, // Adjust based on tab width
        //   transform: `translateX(-${scrollX}px)`,
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        {tabsData.map((tab, index) => (
            tab===tabsData[1] && hideScheduleTab ?null:
          <div
            key={index}
            onClick={() => handleTabClick(tab)}
            style={{
              textAlign: 'center',
              cursor: 'pointer',
              margin: '0 5px',
              padding: '5px',
              fontWeight:selectedTab===tab?'bold':'normal',
            }}
          >
            {tab}
            {
                    selectedTab ===tab
                      ?
                      <span className="sliderlineactive"></span>
                      :
                      null
                  }
          </div>

        ))}
      </div>
    </div>
  );
};

export default HorizontalContestTab;
