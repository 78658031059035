import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { customMultipartRequest, customRequest, customRequestGET } from "../functions/request";
import { MdPinDrop, MdImage, MdMusicVideo, MdAudiotrack, MdLink, MdPoll } from "react-icons/md";
import { imageRootlink, videoRootlink } from "../functions/link";
import { useDispatch } from "react-redux";
import { setShowAlertPopup } from "../redux/dataSlice";
import { isValidURL } from "../functions/helper";
import { setAlert } from "../redux/alertSlice";
import { Colors } from "../constants/colors";
import useNavigateWithIdentification from "../hooks/navigateWIthIdentification";

export default function EditPostScreen() {
    let navigate = useNavigate();
    let params = useParams();
    let [user, setUser] = React.useState(JSON.parse(localStorage.getItem('@user')))
    const { navigateWithIdentification } = useNavigateWithIdentification()
    let [languages, setLanguages] = React.useState(null);
    let [location, setLocation] = React.useState('');
    let [selectedLanguage, setSelectedLanguage] = React.useState('');
    let [media, setMedia] = React.useState(null);
    let [title, setTitle] = React.useState('');
    let [description, setDescription] = React.useState('');
    let [selectedMediaType, setSelectedMediaType] = React.useState('');
    let [moods, setMoods] = React.useState(null);
    let [selectedMood, setSelectedMood] = React.useState('');
    let [showSourceLink, setShowSourceLink] = React.useState('none');
    let [selectedSource, setSelectedSource] = React.useState('');
    let [postSourceLink, setPostSourceLink] = React.useState('');
    let [postid, setPostid] = React.useState('');
    const [postMedia, setPostMedia] = React.useState(null);
    const dispatch = useDispatch();
    const [imageError, setImageError] = React.useState(false)
    let [postlink, setPostLink] = React.useState('');

    
    const handleImageError = () => {
        //  console.log('error in image')
        setImageError(true)

    }

    const handleInput = (e) => {
        if (e.target.name == "title") {
            setTitle(e.target.value);
        } else if (e.target.name == "description") {
            setDescription(e.target.value);
        }    else if (e.target.name == "media") {
            let element;
            if (selectedMediaType === "image") {
                element = document.createElement('img');
            } else if (selectedMediaType === "video") {
                element = document.createElement('video');
                element.setAttribute('controls', 'true');
                // element.setAttribute('')
            }


            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                element.src = reader.result;
                element.setAttribute('style', "width:calc(100vw - 40px);height:160px;object-fit:cover;border-radius:20px;")
                //  console.log(reader.result);
                document.getElementById('mediadisplay').innerHTML = '';
                document.getElementById('mediadisplay').append(element);
            }
            setMedia(
                e.target.files[0]
            )
        }
    };

    const handleGetLanguage = async () => {
        customRequest('post/languages').then((res) => {
            setLanguages(
                res.languages.map((lang) => {
                    return (
                        <div key={lang.id} onClick={() => { setSelectedLanguage(lang.id) }} style={{ border: selectedLanguage == lang.id ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedLanguage == lang.id ? "#6945B4" : "gray", backgroundColor: selectedLanguage == lang.id ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                            {lang.language_name}
                        </div>
                    )
                })
            )
        })

        customRequest('post/moods', { language_id: selectedLanguage }).then((res) => {
            setMoods(
                res.moods.map((mood) => {
                    return (
                        <div key={mood.id} onClick={() => { setSelectedMood(mood.id) }} style={{ border: selectedMood == mood.id ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMood == mood.id ? "#6945B4" : "gray", backgroundColor: selectedMood == mood.id ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                            {mood.mood_name}
                        </div>
                    )
                })
            )
        })

    }

    const handleUpdatePost = () => {


        if(location === undefined || location === null ||  location === ""){
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please select location to post.",
                    }
                )
            )
            return;
        }

        if (selectedMediaType === 'text' && (!title || !description)) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: 'Alert',
                    message: 'Please enter post title or descriptions.',
                })
            );
            return;
        }

        if (selectedMediaType==="link" && !media) {
            dispatch(
                setShowAlertPopup({
                    show: true,
                    title: 'Alert',
                    message: 'Please provide the link.',
                })
            );
            return;
        }
       if( postlink && !isValidURL(postlink)){
            dispatch(
                setShowAlertPopup(
                    {
                        show: true,
                        title: "Alert",
                        message: "Please provide the valid link.",
                    }
                )
            )  
            return;
        }

        let user = localStorage.getItem('@user');
        user = JSON.parse(user);
        let formdata = new FormData();
        formdata.append('location', location);
        formdata.append('language_id', selectedLanguage);
        formdata.append('title', title);
        formdata.append('description', description)
        formdata.append('source', selectedSource);
        formdata.append('mood_id', selectedMood);
        formdata.append('visible', 0);
        formdata.append('media', media);
        formdata.append('user_id', user.id);
        formdata.append('source_link', postSourceLink);
        formdata.append('postid', postid);


        customMultipartRequest('post/updatepost', formdata).then((res) => {
            console.log(res);
            if (res.message == 'Given data was invalid') {
                alert('Given data was invalid')
            } else {
                dispatch(
                    setAlert(
                        {
                            show: true,
                            message: "Post updated successfully",
                            color:'danger'
                        }
                    )
                )  
                navigateWithIdentification('')
            }
        })
    };

    const handleSelectMediaTypeText = () => {

        setSelectedMediaType('text')

    }

    const handlePostLink = (t) => { 
        setPostLink(t.target.value); 
        setMedia(t.target.value)
        setSelectedMediaType('link')
     }
    const getPostToBeEdited = () => {
        customRequest('post/singlepost/' + params.postid).then((res) => {
            const post = res.post;
            setSelectedLanguage(post.language_id);
            setPostid(post.id);
            setSelectedMood(post.mood.id);
            setSelectedSource(post.source)
            // alert(post.contents[0].description)
            setTitle(post.contents[0].title)
            setDescription(post.contents[0].description);
            setLocation(post.location.locationMaster.location_name);
            setPostSourceLink(post.source_link);
            setPostMedia(post.media ? post.media[0] : null);
            setSelectedMediaType(post?.media?.length!==0 ? post?.media[0]?.type:"text")
            if(post?.media[0]?.type==='link'){
                setPostLink(post.media[0].path)
            }
        })
    }

    React.useEffect(() => {
        getPostToBeEdited();
    }, [])

    React.useEffect(() => {
        handleGetLanguage();
    }, [selectedMood, selectedLanguage])

    return (
        <div className="main_warp" style={{ backgroundColor: Colors.white, overflowX: "hidden" }}>
        <div className="np__header" style={{ position: "relative", justifyContent: "space-between" }}>
                <span style={{ fontWeight: "700", cursor: "pointer" }} onClick={() => { navigate(-1) }}>X</span>
                <span style={{ margin: "0 auto", marginLeft: 70, fontFamily: "Open Sans" }}>Update Post</span>
                <button style={{ border: "none", padding: "2px 10px", fontSize: 14, fontFamily: "Open Sans", borderRadius: 60, background: "#6945B4", color: "white" }} onClick={handleUpdatePost}>Update</button>
            </div>

            <div style={{ display: "flex", alignItems: "center", padding: "0 12px", marginTop: 12 }}>
            <img onError={handleImageError}
                    alt='user_image'
                    src={imageError ? `${process.env.PUBLIC_URL}/profile_image.png` : `${imageRootlink}/profilepic/${user?.profile_pic}`} style={{ width: "46px", height: "46px", borderRadius: 40 }} />                <div style={{ margin: "10px 12px" }}>
                    <h5 style={{ fontSize: "15px", lineHeight: "1px", fontFamily: "Open Sans" }}>{user?.full_name}</h5>
                    {!!location && <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                        <MdPinDrop color="#6945B4" size={12} />
                        <span style={{ border: "1px solid #6945B4", padding: "2px 6px", borderRadius: 60, marginLeft: 4, alignItems: "center", justifyContent: "center", fontSize: 10, color: "#6945B4", backgroundColor: "white", whiteSpace: "nowrap", }}>{location}</span>

                        {/* <span style={{ fontSize: 12, fontFamily: "Open Sans", color: "#6945B4", marginLeft: 4 }}>Add Location</span> */}
                    </div>}
                    {/* <div style={{ display: "flex", alignItems: "center", overflowX: "auto", maxWidth: "90%", overflow: "auto" }}>
                        <div onClick={() => { setLocation(user.user_detail.current_city) }} style={{ border: location == user.user_detail.current_city ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == user.user_detail.current_city ? "#6945B4" : "gray", backgroundColor: location == user.user_detail.current_city ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", cursor: "pointer" }}>
                            {user.user_detail.current_city}
                        </div>
                        {
                            user.user_detail.other_cities.split(',').map(city => {
                                return (
                                    <div onClick={() => { setLocation(city) }} key={Math.random()} style={{ border: location == city ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, marginLeft: 2, alignItems: "center", justifyContent: "center", fontSize: 10, color: location == city ? "#6945B4" : "gray", backgroundColor: location == city ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", cursor: "pointer" }}>
                                        {city}
                                    </div>
                                )
                            })
                        }
                    </div> */}
                </div>
            </div>

            <div style={{ padding: "10px 12px" }}>
                <input type="text" name="title" onChange={handleInput} placeholder="Add title" style={{ border: "none", fontFamily: "Open Sans", fontSize: 20, fontWeight: 500 }} value={title} />
                <textarea name="description" rows={4} onChange={handleInput} placeholder="What’s on your mind?" style={{ border: "none", fontFamily: "Open Sans", fontSize: 16, width: "100%", marginTop: 12 }} value={description}></textarea>
            </div>

            <div id="mediadisplay" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {

                    postMedia && postMedia.type === "image"
                        ?
                        <img src={postMedia.path} style={{ width: "calc(100vw - 40px)", height: "160px", objectFit: "cover", borderRadius: "20px" }} />
                        :
                        postMedia && postMedia.type === "audio"
                            ?
                            <audio src={imageRootlink + "/post/" + postMedia.path}></audio>
                            :
                            postMedia && postMedia.type === "video"
                                ?
                                <video src={imageRootlink + "/post/" + postMedia.path} style={{ width: "calc(100vw - 40px)", height: "160px", objectFit: "cover", borderRadius: "20px" }}></video>
                                :
                                null

                }
            </div>

            <input type="file" onChange={handleInput} style={{ display: "none" }} id="selectMedia" name="media" />


            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Media</h5>

            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
              <label onClick={handleSelectMediaTypeText} style={{ border: selectedMediaType === "text"  ? "1px solid #6945B4" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType === "text"  ? "#6945B4" : "gray", backgroundColor: selectedMediaType === "text"  ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Text
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('image');  }} style={{ border: selectedMediaType === "image" ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType=== "image" ? "#6945B4" : "gray", backgroundColor: selectedMediaType=== "image" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Picture
                </label>
                <label htmlFor="selectMedia"  onClick={() => { setSelectedMediaType('audio');  }} style={{ border: selectedMediaType === "audio" ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType=== "audio" ? "#6945B4" : "gray", backgroundColor: selectedMediaType=== "audio" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Audio
                </label>
                <label htmlFor="selectMedia" onClick={() => { setSelectedMediaType('video');  }} style={{ border: selectedMediaType === "video" ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType=== "video" ? "#6945B4" : "gray", backgroundColor: selectedMediaType=== "video" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Video
                </label>
                <label onClick={() => { setSelectedMediaType('link') }} style={{ border: selectedMediaType==="link"  ? "1px solid #6945B4" : "1px solid gray", padding: "4px 10px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedMediaType==="link"  ? "#6945B4" : "gray", backgroundColor:selectedMediaType==="link"  ?  "white":"rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Link
                </label>
            </div>
            {
                selectedMediaType==="link" 
                    ?
                    <input value={postlink} onChange={handlePostLink} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Enter Media Link" />
                    :
                    null
            }

            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 12, marginBottom: 12 }}>Select Language</h5>
            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                {
                    languages
                }
            </div>


            <h5 style={{ padding: "0 12px", fontSize: 12, marginTop: 12 }}>Select Mood</h5>
            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                {
                    moods
                }
                {/* <div style={{ border: "1px solid gray", padding: "4px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: "gray", backgroundColor: "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px" }}>
                    English
                </div>
                <div style={{ border: "1px solid gray", padding: "4px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: "gray", backgroundColor: "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px" }}>
                    हिन्दी
                </div>
                <div style={{ border: "1px solid gray", padding: "4px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: "gray", backgroundColor: "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px" }}>
                    ಕನ್ನಡ
                </div> */}
            </div>

            <h5 style={{ padding: "0 12px", fontSize: 12, lineHeight: "1px", marginTop: 16, marginBottom: 12 }}>Select Source</h5>
            <div style={{ display: "flex", alignItems: "center", overflowX: "auto" }}>
                <div onClick={() => { setSelectedSource("By Me") }} style={{ border: selectedSource == "By Me" ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "By Me" ? "#6945B4" : "gray", backgroundColor: selectedSource == "By Me" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    By Me
                </div>
                <div onClick={() => { setSelectedSource("Forwarded") }} style={{ border: selectedSource == "Forwarded" ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "Forwarded" ? "#6945B4" : "gray", backgroundColor: selectedSource == "Forwarded" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Forwarded
                </div>
                <div onClick={() => { setSelectedSource("web") }} style={{ border: selectedSource == "web" ? "1px solid #6945B4" : "1px solid gray", padding: "2px 6px", borderRadius: 60, alignItems: "center", justifyContent: "center", fontSize: 12, color: selectedSource == "web" ? "#6945B4" : "gray", backgroundColor: selectedSource == "web" ? "white" : "rgba(0,0,0,0.1)", whiteSpace: "nowrap", marginLeft: "10px", cursor: "pointer" }}>
                    Web
                </div>
            </div>

            {
                selectedSource == "web"
                    ?
                    <input onChange={(t) => { setPostSourceLink(t.target.value) }} value={postSourceLink} style={{ backgroundColor: "rgba(0,0,0,0.03)", border: "1px solid gray", padding: "6px 10px", borderRadius: 10, margin: "10px 10px" }} placeholder="Souce link" />
                    :
                    null
            }
             <div style={{ height: "250px" }}></div>


            {/* <div style={{ borderTop: "1px solid rgba(0,0,0,0.2)", borderBottom: "1px solid rgba(0,0,0,0.2)", marginTop: "auto" }}>
                <label htmlFor="selectMedia" style={{ padding: "6px 12px", alignItems: "center", display: "flex",lineHeight:"1px",cursor:"pointer" }}>
                    <MdImage size={24} />
                    <span style={{ fontFamily: "Open Sans", fontSize: 15, marginLeft: 12 }}>Photo</span>
                    <img src={require("../assets/credit.png")} style={{ height: 20, marginLeft: "auto" }} />
                </label>
                <label htmlFor="selectMedia"  style={{ padding: "6px 12px", alignItems: "center", display: "flex",cursor:"pointer" }}>
                    <MdAudiotrack size={24} />
                    <span style={{ fontFamily: "Open Sans", fontSize: 15, marginLeft: 12 }}>Audio</span>
                    <img src={require("../assets/credit.png")} style={{ height: 20, marginLeft: "auto" }} />
                </label>
                <label htmlFor="selectMedia"  style={{ padding: "6px 12px", alignItems: "center", display: "flex",cursor:"pointer" }}>
                    <MdMusicVideo size={24} />
                    <span style={{ fontFamily: "Open Sans", fontSize: 15, marginLeft: 12 }}>Video</span>
                    <img src={require("../assets/credit.png")} style={{ height: 20, marginLeft: "auto" }} />
                </label>
            </div> */}
            {/* <div className="col-12 d-flex flex-column align-items-center justify-content-center h-100">
                <div className="card p-2 center-block mt-4">
                    <h4 className="text-center">Post</h4>
                    <div className="form-group mt-1">
                        <label htmlFor="phone">Location</label>
                        <input
                            type="text"
                            name="location"
                            placeholder="Location"
                            className="form-control"
                            onChange={handleInput}
                        />
                    </div>
                    <div className="form-group mt-1">
                        <label htmlFor="phone">Language</label>
                        <select
                            name="language"
                            placeholder="language"
                            className="form-control"
                            onChange={handleInput}
                        >
                            <option selected disabled>Select language</option>
                            {
                                languages
                            }
                        </select>
                    </div>
                    <div className="form-group mt-1">
                        <label htmlFor="phone">Content</label>
                        <textarea
                            type="text"
                            name="text"
                            placeholder="Content"
                            className="form-control"
                            onChange={handleInput}
                        ></textarea>
                    </div>
                    <div className="form-group mt-1">
                        <label htmlFor="phone">Media</label>
                        <input
                            type="file"
                            name="media"
                            placeholder="Content"
                            className="form-control"
                            onChange={handleInput}
                        />
                    </div>
                    <div className="form-group mt-1">
                        <label htmlFor="phone">Mood</label>
                        <select
                            name="mood"
                            placeholder="mood"
                            className="form-control"
                            onChange={handleInput}
                        >
                            <option selected disabled>Select Mood</option>
                            {
                                moods
                            }
                        </select>
                    </div>
                    <div className="form-group mt-1">
                        <label htmlFor="phone">Source</label>
                        {
                            locationref.state != null && locationref.state.source
                                ?
                                <select
                                    name="source"
                                    placeholder="Source"
                                    className="form-control"
                                    onChange={handleInput}
                                >
                                    <option selected disabled>Select Source</option>
                                    <option value="Contest">Contest</option>

                                </select>
                                :
                                <select
                                    name="source"
                                    placeholder="Source"
                                    className="form-control"
                                    onChange={handleInput}
                                >
                                    <option selected disabled>Select Source</option>
                                    <option value="By Me">By Me</option>
                                    <option value="Forwarded">Forwarded</option>
                                    <option value="By Web">By Web</option>

                                </select>
                        }
                    </div>
                    <div className="form-group mt-1" style={{ display: showSourceLink }}>
                        <label htmlFor="link">Source Link</label>
                        <input type="url" placeholder="Enter Source Link" name="source_link" className="form-control" />
                    </div>
                    <button className="btn btn-primary" onClick={handleUpdatePost}>
                        Create Post
                    </button>
                </div>
            </div> */}
        </div>
    );
}
