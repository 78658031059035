import { Navigate } from 'react-router-dom'
import { getUserToken } from '../../functions/helper'

const ProtectedRoute = ({ Component }) => {
    const token = getUserToken()



    if (!!token) {
        return <> {<Component />}</>
    } else {
        return <Navigate to="/" replace />
    }


}

export default ProtectedRoute