import React from 'react'
import CustomButton from '../components/CustomButton';
import useNavigateWithIdentification from '../hooks/navigateWIthIdentification';

const ComingSoon = () => {
    const { navigateWithIdentification } = useNavigateWithIdentification()

    return (
        <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light">
            <div className="text-center">
                <h1 className="display-4">Coming Soon</h1>
                <p className="lead">
                    Sorry, the page you are looking for is still under development.
                </p>
            </div>
            <CustomButton label={"Go Back to Home"} onClick={() => navigateWithIdentification("")} />
        </div>

    )
}

export default ComingSoon