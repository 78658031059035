import React, { useEffect, useState } from 'react';
import HomeContestSection from '../components/homeContestSection';
import { useLocation, useParams } from 'react-router-dom';
import UserAvatar from '../components/userAvatar';
import { getUserToken, setIdentificationToken } from '../functions/helper';

export default function ContestListScreen() {
    const location = useLocation()
    const params = useParams()
    const [organisation, setOrganisation] = useState("Gold Gym")
    const token =getUserToken()

    useEffect(() => {
        setIdentificationToken(params?.identificationToken)
    }, [])



    return (
        <div className='main_warp' >
            <div style={{ height: 48, width: "100%", boxShadow: "0 4px 4px rgba(0,0,0,0.1)", marginBottom: 1, display: "flex", alignItems: "center", padding: "0 16px", position: "sticky", top: 0 }}>
                <h4 style={{ fontFamily: "Open Sans", fontSize: 20, marginLeft: 10, lineHeight: "1px", marginTop: 5 }}> Challenges</h4>
                <div style={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}>
                    <button
                        data-bs-toggle="modal"
                        data-bs-target="#languageModal"
                        type="button"
                        className="btn btn-secondary-light search-hide" style={{ padding: "7px 10px", marginLeft: "auto", marginRight: 10 }}>
                        <div className="bd-highlight col-1 px-0 align-self-center pe-1">
                            <div className="tabs-btn" data-bs-toggle="modal"
                                data-bs-target="#languageModal" >
                                <img alt='localbol' src="assets/images/NavIcons.svg" />
                            </div>
                        </div>
                    </button>
                   {token && <UserAvatar />}
                </div>

            </div>
            <HomeContestSection redirectToMyChallenge={location?.state?.redirectToMyChallenge ? true : false} />
        </div>
    )
}