import { useNavigate } from 'react-router-dom';
import { getIdentity } from '../functions/helper';
import useAlert from './useAlert';

const useNavigateWithIdentification = () => {
    const navigate = useNavigate();
    const { showAlertPopup } = useAlert()


    const navigateWithIdentification = (path, state, ...rest) => {
        const identity = getIdentity()
        const identificationToken = identity?.identification_token
        if (!identificationToken) {
            showAlertPopup('Something went wrong')
            localStorage.clear()
            return;
        }
        const sanitizedPath = identificationToken.replace(/^"|"$/g, '');  // Remove quotes from start and end
        if (path === "") {//empty string means home screen
            navigate(`/${sanitizedPath}`, { state: { ...state } }, ...rest);
        }
        else {
            navigate(`/${sanitizedPath}/${path}`, { state: { ...state } }, ...rest);

        }
    };

    return { navigateWithIdentification };
};

export default useNavigateWithIdentification;
